@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
#global_header, #contents_wrap, #global_footer {
  display: none;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 2em;
  font-weight: 500;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  letter-spacing: 0.05em;
  font-size: 16px;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 1000px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  z-index: 3;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #contents {
    padding: 0 30px 50px;
  }
}

@media only screen and (max-width: 640px) {
  #contents {
    padding: 0 40px 50px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #ff0000;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 1000px) {
  .dis1000 {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  .dis1000 {
    display: block;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  z-index: 3;
  position: relative;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 80px;
  }
}

#header {
  position: fixed;
  z-index: 11;
  top: 0px;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 834px) {
  #header {
    background: #fff;
  }
}

#header.bgh {
  background: #fff;
}

#header .inner {
  max-width: 100%;
  margin-left: calc(((1200px - 100%) / 2) * -1) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  #header .inner {
    margin-left: 0 !important;
  }
}

#header .inner .flxL {
  width: auto;
}

@media only screen and (max-width: 1400px) {
  #header .inner .flxL {
    margin-left: 10px;
  }
}

#header .inner .flxL #siteID {
  text-align: left;
}

#header .inner .flxL #siteID a {
  display: inline-block;
}

#header .inner .flxR {
  width: 80%;
  display: flex;
  justify-content: flex-end;
}

#header .inner .flxR .clone-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR .clone-nav {
    display: none;
  }
}

#header .inner .flxR .clone-nav .clonenone {
  display: none;
}

#header .inner .flxR .clone-nav > li {
  height: 80px;
}

@media only screen and (max-width: 1000px) {
  #header .inner .flxR .clone-nav > li {
    position: relative;
  }
}

#header .inner .flxR .clone-nav > li > p {
  height: 100%;
}

#header .inner .flxR .clone-nav > li > p > a {
  height: 100%;
  display: block;
  color: #000;
  font-weight: 700;
  padding: 30px 20px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1em;
}

#header .inner .flxR .clone-nav > li > p > a:hover {
  opacity: 1;
  background: #000;
  color: #fff;
}

#header .inner .flxR .clone-nav > li > p > a span {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  display: block;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
}

#header .inner .flxR .clone-nav > li > a {
  height: 100%;
  display: block;
  color: #000;
  font-weight: 700;
  padding: 30px 20px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1em;
}

#header .inner .flxR .clone-nav > li > a:hover {
  opacity: 1;
  background: #000;
  color: #fff;
}

#header .inner .flxR .clone-nav > li > a span {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  display: block;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
}

#header .inner .flxR .clone-nav > li ul {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
}

@media only screen and (max-width: 1000px) {
  #header .inner .flxR .clone-nav > li ul {
    display: block;
    width: 150%;
    margin-left: -25%;
  }
}

#header .inner .flxR .clone-nav > li ul li {
  flex-grow: 1;
}

@media only screen and (max-width: 1000px) {
  #header .inner .flxR .clone-nav > li ul li {
    border-bottom: solid 1px #ddd;
  }
  #header .inner .flxR .clone-nav > li ul li:last-child {
    border-bottom: 0;
  }
}

#header .inner .flxR .clone-nav > li ul li a {
  height: 100%;
  background: black;
  display: block;
  padding: 20px 10px;
  font-size: 13px;
  color: #fff;
  opacity: 1;
}

#header .inner .flxR .clone-nav > li ul li a:after {
  content: "\f0a9";
  margin-left: 10px;
  font-family: "Font Awesome 5 pro";
}

@media only screen and (max-width: 1000px) {
  #header .inner .flxR .clone-nav > li ul li a:after {
    display: none;
  }
}

#header .inner .flxR .clone-nav > li ul li a:hover {
  background: #333333;
}

#header .inner .flxR #hed_info {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR #hed_info {
    display: block;
  }
}

#header .inner .flxR #hed_info .store1 a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff9900;
  color: #fff;
  font-size: 30px;
  opacity: 1;
  width: 80px;
  height: 80px;
}

#header .inner .flxR #hed_info .store1 a:hover {
  background: #ff8100;
}

#header .inner .flxR #hed_info .store1 a i {
  font-weight: 400;
}

@-webkit-keyframes menu-bar01 {
  0% {
    -webkit-transform: translateY(9px) rotate(45deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(9px) rotate(45deg);
  }
  50% {
    transform: translateY(9px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@-webkit-keyframes menu-bar02 {
  0% {
    -webkit-transform: translateY(-9px) rotate(-45deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}

@keyframes menu-bar02 {
  0% {
    transform: translateY(-9px) rotate(-45deg);
  }
  50% {
    transform: translateY(-9px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.global-menu-btn {
  position: relative;
  cursor: pointer;
  height: 80px;
  width: 80px;
  font-size: 13px;
  z-index: 11;
  line-height: 1;
  transition: 1s;
  color: #fff;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.global-menu-btn::after {
  content: "MENU";
  width: 100%;
  text-align: center;
  font-size: 11px;
  letter-spacing: .5px;
  margin-top: 10px;
}

.global-menu-btn .menu-trigger {
  position: relative;
  width: 30px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-menu-btn .menu-trigger i {
  display: block;
  width: 30px;
  height: 2px;
  background: #fff;
  position: absolute;
}

.global-menu-btn .menu-trigger i:nth-child(1) {
  top: 0;
  animation: menu-bar01 .75s forwards;
}

.global-menu-btn .menu-trigger i:nth-child(2) {
  width: 20px;
  top: 9px;
  opacity: 1;
}

.global-menu-btn .menu-trigger i:nth-child(3) {
  bottom: 0;
  animation: menu-bar02 .75s forwards;
}

.navdamy {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}

#hednav {
  justify-content: center;
  height: 80px;
  width: 80px;
  z-index: 11;
}

#hednav .wrap {
  visibility: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all .2s ease-out;
  background: rgba(0, 0, 0, 0.6) !important;
  display: block !important;
  position: fixed;
  width: 100% !important;
  height: 100%;
  top: 0;
  right: 0;
}

#hednav .wrap .navwrap {
  display: block !important;
  background: #eef2f2;
  width: 40%;
  height: 100%;
  opacity: 0;
  margin-left: auto;
  transform: translateX(240%);
  transition: all 0.5s ease-out;
  padding-top: 80px;
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap {
    width: 70%;
  }
}

#hednav .wrap .navwrap #nav_global {
  width: 100%;
  margin-top: 0 !important;
  background: none;
}

#hednav .wrap .navwrap #nav_global > ul {
  flex-direction: column;
  margin: 0 auto;
}

#hednav .wrap .navwrap #nav_global > ul .pcnone {
  display: block !important;
}

#hednav .wrap .navwrap #nav_global > ul .clonenone {
  display: block;
}

#hednav .wrap .navwrap #nav_global > ul > li {
  text-align: left;
  position: relative;
  width: 100%;
  border-bottom: solid 1px #ddd;
}

#hednav .wrap .navwrap #nav_global > ul > li a {
  line-height: 1.7em;
  color: #000;
  font-weight: 700 !important;
  display: block;
  padding: 26px 36px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

#hednav .wrap .navwrap #nav_global > ul > li a:hover {
  opacity: 1;
  color: #fff;
  background: #000 !important;
}

#hednav .wrap .navwrap #nav_global > ul > li a img {
  width: 25%;
}

#hednav .wrap .navwrap #nav_global > ul > li a span {
  width: 70%;
  display: block;
}

#hednav .wrap .navwrap #nav_global > ul > li a span b {
  display: block;
}

#hednav .wrap .navwrap #nav_global > ul > li a span span {
  display: block;
  font-size: 0.8em;
  color: #333;
}

#hednav .wrap .navwrap #nav_global > ul > li ul {
  display: block;
  width: 100%;
  position: static;
  background: none;
}

#hednav .wrap .navwrap #nav_global > ul > li ul li a {
  background: #f7f7f7;
  color: #333;
  font-weight: 500;
  display: block;
}

#hednav .wrap .navwrap #nav_global > ul > li p {
  position: relative;
  display: block;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

#hednav .wrap .navwrap #nav_global > ul > li p:hover:after {
  color: #333;
}

#hednav .wrap .navwrap #nav_global > ul > li p span {
  display: block;
  margin-top: 7px;
  font-size: 0.7em;
}

#hednav .wrap .navwrap #nav_global > ul > li p:after {
  border: solid 1px #ddd;
  font-weight: 100;
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  display: block;
  position: absolute;
  right: 0;
  top: -1px;
  bottom: 0;
  width: 79px;
  height: 79px;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f7f6;
  z-index: 1;
}

#hednav .wrap .navwrap #nav_global > ul > li p.open:after {
  content: "\f00d";
}

@-webkit-keyframes active-menu-bar01 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }
  50% {
    -webkit-transform: translateY(20px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(20px) rotate(45deg);
  }
}

@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(9px) rotate(0);
  }
  100% {
    transform: translateY(9px) rotate(45deg);
  }
}

@-webkit-keyframes active-menu-bar03 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(-45deg);
  }
}

@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-9px) rotate(0);
  }
  100% {
    transform: translateY(-9px) rotate(-45deg);
  }
}

.MenuOpened {
  z-index: 13 !important;
  display: none;
  display: block;
}

.MenuOpened .global-menu-btn {
  z-index: 11;
}

.MenuOpened .global-menu-btn i:nth-child(1) {
  animation: active-menu-bar01 .75s forwards;
}

.MenuOpened .global-menu-btn i:nth-child(2) {
  opacity: 0;
}

.MenuOpened .global-menu-btn i:nth-child(3) {
  animation: active-menu-bar03 .75s forwards;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
  margin-left: auto;
}

@media only screen and (max-width: 834px) {
  #mainArea2 {
    margin-top: 0 !important;
    margin-bottom: 80px;
  }
}

#local-keyvisual {
  overflow: hidden;
  position: relative;
  background-position: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual {
    margin: 0 auto 50px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
}

#local-keyvisual #local-keyvisual-title {
  width: 100%;
  padding: 100px 10px;
}

#local-keyvisual #local-keyvisual-title h1 {
  float: left;
  padding: 100px 100px;
  background: #fff;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1;
  color: #000 !important;
  z-index: 1;
  position: relative;
  height: 100%;
  text-align: left;
  line-height: 1.4em;
  display: inline-block;
  font-size: 20px;
}

@media only screen and (max-width: 1000px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual #local-keyvisual-title h1 {
    padding: 50px 30px;
  }
}

#local-keyvisual #local-keyvisual-title h1 span {
  margin-bottom: 15px;
  display: block;
  font-size: 2em;
  line-height: 1;
}

#local-keyvisual #local-keyvisual-title h1:empty {
  display: none;
}

#local-keyvisual .local-keyvisual-bg {
  margin-left: calc(((1200px - 100%) / 2) * -1) !important;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual .local-keyvisual-bg {
    margin-left: 0 !important;
  }
}

.pan1 {
  margin: 20px 0 50px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #221815;
}

.pan1 ol li {
  display: inline-block;
}

#mainArea {
  position: relative;
  z-index: 0;
}

#mainArea .maintxt {
  z-index: 1;
  position: absolute;
  left: 10%;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#mainArea .maintxt h1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 50px;
  color: #000;
  font-weight: 700;
  line-height: 1.7em;
  text-align: left;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 1400px) {
  #mainArea .maintxt h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  #mainArea .maintxt h1 {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #mainArea .maintxt h1 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea .maintxt h1 {
    font-size: 20px;
  }
}

#mainArea .maintxt h1 span {
  display: inline-block;
  background: #fff;
  padding: 5px;
  margin-top: 10px;
}

#mainArea img {
  width: 100% !important;
}

#mainArea button {
  display: none !important;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  z-index: 1;
  position: relative;
  color: #fff;
}

footer#global_footer a {
  color: #fff;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer {
  background: #000;
}

#footer .inner {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  #footer .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 1000px) {
  #footer .inner {
    display: block;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer .inner {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 834px) {
  #footer .inner {
    display: block;
  }
}

#footer .inner > .flxL {
  width: 30%;
  padding: 5% 5% 5% 0;
  border-right: 2px solid #fff;
}

@media only screen and (max-width: 1000px) {
  #footer .inner > .flxL {
    width: 100%;
    border-right: 0;
    padding: 3%;
  }
}

#footer .inner > .flxL #siteID {
  margin-bottom: 10px;
  text-align: left;
}

@media only screen and (max-width: 1000px) {
  #footer .inner > .flxL #siteID {
    text-align: center;
  }
}

#footer .inner > .flxL #siteID a {
  display: inline-block;
}

#footer .inner > .flxL .txt {
  margin-top: 15px;
  text-align: left;
}

@media only screen and (max-width: 1000px) {
  #footer .inner > .flxL .txt {
    text-align: center;
  }
}

#footer .inner > .flxL .tel2 {
  margin-top: 15px;
  text-align: left;
}

@media only screen and (max-width: 1000px) {
  #footer .inner > .flxL .tel2 {
    text-align: center;
  }
}

#footer .inner > .flxL .tel2 a {
  font-size: 25px;
  font-weight: 900;
}

#footer .inner > .flxL .cont {
  text-align: left;
  margin-top: 15px;
  max-width: 250px;
}

@media only screen and (max-width: 1000px) {
  #footer .inner > .flxL .cont {
    margin: 15px auto 0;
  }
}

#footer .inner > .flxL .cont a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
}

#footer .inner > .flxL .cont a:after {
  margin-left: 20px;
  font-family: "Font Awesome 5 Pro";
  content: "\f178";
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#footer .inner > .flxR {
  width: 70%;
}

@media only screen and (max-width: 1000px) {
  #footer .inner > .flxR {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner > .flxR {
    flex-wrap: wrap;
    display: none;
  }
}

#footer .inner > .flxR .contactfwrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5%;
  border-bottom: 2px solid #fff;
}

@media only screen and (max-width: 1000px) {
  #footer .inner > .flxR .contactfwrap {
    padding: 3%;
  }
}

@media only screen and (max-width: 834px) {
  #footer .inner > .flxR .contactfwrap {
    display: none;
  }
}

#footer .inner > .flxR .contactfwrap .flxL .ttl {
  text-align: left;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 900;
  line-height: 1;
  font-size: 30px;
}

@media only screen and (max-width: 1200px) {
  #footer .inner > .flxR .contactfwrap .flxL .ttl {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  #footer .inner > .flxR .contactfwrap .flxL .ttl {
    font-size: 25px;
  }
}

#footer .inner > .flxR .contactfwrap .flxL .ttl span {
  line-height: 1;
  display: block;
  margin-top: 10px;
  font-size: 18px;
}

@media only screen and (max-width: 834px) {
  #footer .inner > .flxR .contactfwrap .flxL .ttl span {
    font-size: 16px;
  }
}

#footer .inner > .flxR .contactfwrap .flxL .txt {
  text-align: left;
}

#footer .inner > .flxR .contactfwrap .flxR .cont {
  margin-bottom: 15px;
}

#footer .inner > .flxR .contactfwrap .flxR a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
}

#footer .inner > .flxR .contactfwrap .flxR a:after {
  margin-left: 20px;
  font-family: "Font Awesome 5 Pro";
  content: "\f178";
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#footer .inner > .flxR .navwrap {
  padding: 5% 5% 0 5%;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  #footer .inner > .flxR .navwrap {
    padding: 3%;
  }
}

#footer .inner > .flxR .navwrap ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 1000px) {
  #footer .inner > .flxR .navwrap ul {
    justify-content: center;
  }
}

#footer .inner > .flxR .navwrap ul li {
  display: block;
}

#footer .inner > .flxR .navwrap ul li a {
  padding: 0 10px;
  text-align: left;
}

#footer .inner > .flxR .navwrap ul li a:hover {
  opacity: 1 !important;
}

#copy {
  margin-top: 2px;
  background: #000;
  padding: 10px 0;
  text-align: center;
  font-size: 13px;
  display: block;
  line-height: 1.7em;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 13px;
  color: #fff !important;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

.btn {
  overflow: hidden;
  position: relative;
  display: block;
  max-width: 350px;
  margin: 0 auto;
}

.btn a {
  opacity: 1 !important;
  text-align: center;
  transition: 400ms;
  text-decoration: none;
  z-index: 2;
  border: 1px solid #ff9900;
  font-size: 16px;
  display: block;
  padding: 20px 10px;
  line-height: 1;
  color: #ff9900;
}

.btn a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f178";
  position: absolute;
  right: 20px;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn a:hover {
  opacity: 1 !important;
  background: #ff8100;
  color: #fff;
}

.btn a span {
  position: relative;
  z-index: 1;
}

.btn2 {
  z-index: 0;
  flex-wrap: wrap;
  justify-content: space-around !important;
}

@media only screen and (max-width: 640px) {
  .btn2 {
    display: block;
  }
}

.btn2 .box {
  overflow: hidden;
  position: relative;
  display: block;
  width: 48%;
  margin: 0 auto 20px;
}

@media only screen and (max-width: 640px) {
  .btn2 .box {
    width: 100%;
  }
}

.btn2 a {
  position: relative;
  opacity: 1 !important;
  text-align: center;
  transition: 400ms;
  text-decoration: none;
  z-index: 2;
  border: 1px solid #000;
  font-size: 14px;
  display: block;
  padding: 25px 10px;
  line-height: 1;
  color: #000;
}

.btn2 a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f178";
  position: absolute;
  right: 20px;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn2 a:hover {
  opacity: 1 !important;
  background: #000;
  color: #fff;
}

.btn2 a span {
  position: relative;
  z-index: 1;
}

.btn3 {
  z-index: 0;
  flex-wrap: wrap;
  justify-content: space-around !important;
}

.btn3 a {
  max-width: 300px;
  position: relative;
  opacity: 1 !important;
  text-align: center;
  transition: 400ms;
  text-decoration: none;
  z-index: 2;
  border: 1px solid #000;
  font-size: 14px;
  display: block;
  padding: 25px 10px;
  line-height: 1;
  color: #000;
}

@media only screen and (max-width: 640px) {
  .btn3 a {
    width: 100%;
    max-width: 100%;
  }
}

.btn3 a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f178";
  position: absolute;
  right: 20px;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn3 a:hover {
  opacity: 1 !important;
  background: #000;
  color: #fff;
}

.btn3 a span {
  position: relative;
  z-index: 1;
}

.moveRel {
  overflow: hidden;
  position: relative;
}

.moveWrap {
  z-index: 999;
  background: #c69f21;
  position: absolute;
  width: 0;
  top: 0;
}

.cont_1 .inner {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .cont_1 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner {
    display: block;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_1 .inner {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_1 .inner {
    padding: 0 40px;
  }
}

.cont_1 .inner .ttl {
  width: 20%;
  font-weight: 700;
  color: #fff;
  background: #000;
  font-size: 35px;
}

@media only screen and (max-width: 1200px) {
  .cont_1 .inner .ttl {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .ttl {
    width: 100%;
    font-size: 25px;
  }
}

.cont_1 .inner .ttl span {
  display: block;
  margin-top: 10px;
  font-size: 18px;
}

@media only screen and (max-width: 1200px) {
  .cont_1 .inner .ttl span {
    font-size: 16px;
  }
}

.cont_1 .inner .ttl article {
  height: 100%;
}

.cont_1 .inner .ttl article h2 {
  line-height: 1;
  padding: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cont_1 .inner .txt {
  width: 78%;
  padding: 0 20px 0 0;
  overflow-y: scroll;
  height: 190px;
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .txt {
    width: 100%;
    height: 300px;
    padding: 15px;
    background: #f7f7f7;
  }
}

.cont_1 .inner .txt dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
}

.cont_1 .inner .txt dl dt {
  padding: 0 !important;
  width: auto !important;
  border: 0 !important;
  background-color: inherit !important;
  margin-right: 10px;
}

.cont_1 .inner .txt dl dd {
  font-size: 13px;
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.cont_2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
  .cont_2 {
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_2 {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_2 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2 {
    flex-direction: column-reverse;
  }
}

.cont_2:nth-child(odd) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .cont_2:nth-child(odd) {
    flex-direction: column-reverse;
  }
}

.cont_2:nth-child(odd) .flxR {
  margin-right: auto;
  margin-left: 0;
}

.cont_2 .flxL {
  background: #fff;
  position: absolute;
  padding: 3%;
  width: 30%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1200px) {
  .cont_2 .flxL {
    width: 45%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2 .flxL {
    width: 100%;
    padding: 10%;
    position: static;
  }
}

.cont_2 .flxR {
  margin-left: auto;
  width: 60%;
}

@media only screen and (max-width: 1200px) {
  .cont_2 .flxR {
    width: 80%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2 .flxR {
    width: 100%;
  }
}

.cont_2 .flxR .img {
  width: 100%;
}

.cont_2 .flxR .img img {
  width: 100% !important;
}

.cont_3 {
  background: #f5f4f3;
  padding: 80px 0;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_3 .inner {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .inner {
    padding: 0 40px;
  }
}

.cont_3 .boxwrap {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .boxwrap {
    padding: 0 10px;
  }
}

.cont_3 .boxwrap .box a {
  width: 100%;
  color: #333;
}

.cont_3 .boxwrap .box img {
  padding-bottom: 10px;
  width: 100% !important;
}

.cont_3 .boxwrap .box h3 {
  width: 100%;
  font-size: 16px;
  color: #222;
  padding-bottom: 10px !important;
}

.cont_3 .boxwrap .box div {
  font-size: 13px;
  background: #fff;
  padding: 10px !important;
  border-radius: 5px;
}

.cont_3 .owl-stage {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cont_3 .owl-stage .owl-item li {
  height: 100%;
}

.cont_3 .owl-stage .owl-item article {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.cont_3 .owl-stage .owl-item article a:hover img {
  opacity: 1 !important;
}

.cont_3 .owl-nav {
  display: none;
}

.cont_3 .owl-dots {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cont_3 .owl-dots button {
  margin: 0 5px;
  padding: 0;
  box-shadow: inherit;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 0;
  background: #ff9900;
}

.cont_3 .owl-dots .active {
  background: #bfbfbf;
}

.cont_4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(((1200px - 100%) / 2) * -1) !important;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .cont_4 {
    margin-left: 0 !important;
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_4 {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_4 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_4 {
    flex-direction: column-reverse;
  }
}

.cont_4 .flxL {
  background: #fff;
  position: absolute;
  padding: 3%;
  width: 40%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1200px) {
  .cont_4 .flxL {
    width: 45%;
  }
}

@media only screen and (max-width: 1000px) {
  .cont_4 .flxL {
    width: 60%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_4 .flxL {
    width: 100%;
    padding: 10%;
    position: static;
  }
}

.cont_4 .flxR {
  margin-left: auto;
  width: 80%;
}

@media only screen and (max-width: 834px) {
  .cont_4 .flxR {
    width: 100%;
  }
}

.cont_4 .flxR .img {
  width: 100%;
}

.cont_4 .flxR .img img {
  width: 100% !important;
}

.cont_5 {
  background: #f5f4f3;
  padding: 80px 0;
}

@media only screen and (max-width: 1200px) {
  .cont_5 {
    padding: 80px 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_5 {
    padding: 80px 30px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_5 {
    padding: 80px 40px;
  }
}

.cont_5 .wrap {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1000px) {
  .cont_5 .wrap {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_5 .wrap {
    justify-content: space-between;
    margin-left: 0;
  }
}

.cont_5 .wrap .box {
  position: relative;
  width: 46.77419%;
  height: 46.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .cont_5 .wrap .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_5 .wrap .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.cont_5 .wrap .box img {
  width: 100% !important;
}

.cont_5 .wrap .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.cont_5 .wrap .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

/* title */
h2.mail.title {
  display: none !important;
}

.midashi {
  text-align: center;
}

.midashi h2 {
  color: #222;
  font-weight: 900;
  line-height: 1.7em;
  font-size: 30px;
}

@media only screen and (max-width: 1200px) {
  .midashi h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi h2 {
    font-size: 25px;
  }
}

.midashi h2 span {
  line-height: 1;
  display: block;
  margin-top: 10px;
  font-size: 18px;
}

@media only screen and (max-width: 834px) {
  .midashi h2 span {
    font-size: 16px;
  }
}

.midashi2 {
  text-align: center;
}

.midashi2 h2 {
  color: #222;
  font-weight: 900;
  line-height: 1;
  font-size: 35px;
}

@media only screen and (max-width: 1200px) {
  .midashi2 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

.midashi2 h2 span {
  display: block;
  margin-top: 10px;
  font-size: 18px;
}

@media only screen and (max-width: 834px) {
  .midashi2 h2 span {
    font-size: 16px;
  }
}

.midashi3 {
  width: 100%;
}

.midashi3 h2 {
  font-weight: 700;
  background: #f3f3f3;
  padding: 1em 0.5em 1em 1.5em;
  position: relative;
  font-size: 25px;
}

.midashi3 h2:after {
  content: "";
  width: 5px;
  height: 20px;
  border-radius: 500px;
  background: #ff9900;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.midashi4 h2 {
  font-weight: 700;
  position: relative;
  color: #ff9900;
  font-size: 20px;
}

.midashi4 h2 span {
  display: inline-block;
  background: #c6a10c;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  margin-right: 10px;
}

.midashi5 {
  width: 100%;
}

.midashi5 h2 {
  font-weight: 700;
  border-bottom: 5px solid #ff9900;
  padding: 0 0 15px 0;
  position: relative;
  font-size: 25px;
  color: #ff9900;
}

.midashi6 h4 {
  background: #ff9900;
  padding: 10px;
  color: #fff;
  font-weight: 700;
  line-height: 1.7em;
  font-size: 18px;
  font-size: 0.9375vw;
}

@media only screen and (min-width: 1600px) {
  .midashi6 h4 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi6 h4 {
    font-size: 18px;
  }
}

.midashi7 {
  width: 100%;
}

.midashi7 h3 {
  font-weight: 700;
  border-bottom: 5px solid #ff9900;
  padding: 0 0 10px 0;
  position: relative;
  font-size: 18px;
  color: #ff9900;
}

.bg01 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: #f5f4f3;
  padding: 80px 0;
}

@media only screen and (max-width: 1200px) {
  .bg01 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bg01 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .bg01 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.flex3cl .wrap {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1000px) {
  .flex3cl .wrap {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl .wrap {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .wrap .box {
  position: relative;
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .flex3cl .wrap .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl .wrap .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .wrap .box img {
  width: 100% !important;
}

.flex3cl .wrap .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .wrap .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.company01 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .company01 {
    display: block;
  }
}

.company01 .ttl {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  background: #fff;
  padding: 10px;
  background: #fdbb2a;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .company01 .ttl {
    position: static;
    height: auto;
  }
}

.company01 .wrap {
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .company01 .wrap {
    flex-wrap: inherit !important;
  }
}

.company01 .wrap .box {
  margin-right: 10px;
}

.company01 .flxL {
  position: relative;
  background: #fff;
  padding: 60px 20px 20px;
  width: 48%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 834px) {
  .company01 .flxL {
    width: 100%;
    padding: 10%;
  }
}

.company01 .flxR {
  position: relative;
  background: #fff;
  padding: 60px 20px 20px;
  width: 48%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 834px) {
  .company01 .flxR {
    width: 100%;
    padding: 10%;
  }
}

.company01 .flxR .img {
  width: 100%;
}

.company01 .flxR .img img {
  width: 100% !important;
}

.company_area dt, .company_area dd {
  background: none !important;
  border: none !important;
  padding: 1.5em 1em !important;
  font-size: 1rem;
}

.company_area dt {
  border-bottom: 1px solid #ccc !important;
}

.company_area dd {
  border-bottom: 1px solid #eee !important;
}

@media screen and (max-width: 640px) {
  .company_area dl {
    display: flex;
    flex-flow: column;
  }
  .company_area dt {
    border-bottom: 0 !important;
    background: #f9f9f9 !important;
    font-weight: bold;
  }
  .company_area dt, .company_area dd {
    padding: 5px !important;
    display: block;
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .comp dl {
    display: flex;
    flex-flow: column;
  }
  .comp dt {
    border-bottom: 0 !important;
    font-weight: bold;
  }
  .comp dt, .comp dd {
    display: flex !important;
    padding: 5px !important;
    display: block;
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap;
  }
}

.concept {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .concept {
    flex-direction: column-reverse;
  }
}

.concept:nth-child(odd) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .concept:nth-child(odd) {
    flex-direction: column-reverse;
  }
}

.concept:nth-child(odd) .flxR {
  margin-left: 0;
  margin-right: auto;
}

.concept .flxL {
  background: #fff;
  position: absolute;
  padding: 3%;
  width: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1200px) {
  .concept .flxL {
    width: 55%;
  }
}

@media only screen and (max-width: 1000px) {
  .concept .flxL {
    width: 60%;
  }
}

@media only screen and (max-width: 834px) {
  .concept .flxL {
    width: 100%;
    padding: 10%;
    position: static;
  }
}

.concept .flxR {
  margin-left: auto;
  width: 80%;
}

@media only screen and (max-width: 834px) {
  .concept .flxR {
    width: 100%;
  }
}

.concept .flxR .img {
  width: 100%;
}

.concept .flxR .img img {
  width: 100% !important;
}

.ancinner > div {
  margin-top: -100px;
  padding-top: 100px;
}

.flex3cl2 {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .flex3cl2 {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl2 {
    justify-content: space-between;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl2 .box {
  position: relative;
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .flex3cl2 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl2 .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl2 .box img {
  width: 100% !important;
}

.flex3cl2 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl2 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.about_01 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: #f5f4f3;
  padding: 80px 0;
}

@media only screen and (max-width: 1200px) {
  .about_01 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .about_01 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .about_01 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.about_01:nth-child(odd) {
  background: none;
}

.about_01 .img {
  justify-content: center;
}

.about_02wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .about_02wrap {
    display: block;
  }
}

.about_02wrap .about_02 {
  background: #eaeaea;
  padding: 20px;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .about_02wrap .about_02 {
    width: 100%;
    margin-bottom: 20px;
  }
}

.about_02wrap .about_02 .img {
  justify-content: center;
}

.about_02wrap .about_02 .midashi5 article {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.about_02wrap .about_02 .midashi5 article div {
  margin-left: 20px;
  max-width: 100px;
  background: red;
  padding: 2px 5px;
  color: #fff;
}

.about_02wrap .about_02 .midashi5 article div:empty {
  display: none;
}

.about_02wrap .about_02 .flxL {
  width: 100%;
  margin-bottom: 20px;
}

.about_02wrap .about_02 .flxL .img {
  flex-wrap: inherit !important;
}

.about_02wrap .about_02 .flxR {
  width: 100%;
}

.about_02wrap .about_02 .flxR .btn3 {
  max-width: 100%;
  width: 100%;
}

.slider02 {
  width: 100%;
}

.slider02 .slick-prev, .slider02 .slick-next {
  top: 40% !important;
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-prev, .slider02 .slick-next {
    top: 30% !important;
  }
}

.slider02 .slick-prev {
  left: 10px !important;
  z-index: 1;
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-prev {
    left: 0 !important;
  }
}

.slider02 .slick-next {
  right: 10px !important;
  z-index: 1;
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-next {
    right: 0 !important;
  }
}

.slider02 .slick-slide {
  height: 670px;
  max-width: 1000px;
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-slide {
    height: auto;
  }
}

.slider02 .slick-slide img {
  width: 100% !important;
  height: auto !important;
}

.slider02 .slick-slide article div {
  margin-bottom: 20px;
  font-size: 16px;
}

.slider02 .slick-list {
  padding: 10px 0;
}

.slider02 .slick-dots {
  position: static !important;
  display: -webkit-flex !important;
  display: flex !important;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-dots {
    margin-top: 20px !important;
  }
}

.slider02 .slick-dots li {
  list-style: none;
  width: 13.2% !important;
  margin: 0 !important;
  margin-right: 1.2% !important;
  margin-bottom: 10px !important;
  height: 100px !important;
  object-fit: contain !important;
  font-family: "object-fit: contain!important;";
  overflow: hidden;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .slider02 .slick-dots li {
    width: 18.8% !important;
  }
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-dots li {
    height: 25% !important;
  }
}

.slider02 .slick-dots li:nth-of-type(7n) {
  margin-right: 0 !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .slider02 .slick-dots li:nth-of-type(7n) {
    margin-right: 1.2% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .slider02 .slick-dots li:nth-of-type(5n) {
    margin-right: 0;
  }
}

.slider02 .slick-dots li img {
  width: auto !important;
}

.oem01 ul {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.oem01 ul li {
  width: 32%;
}

.about_03 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .about_03 {
    display: block;
  }
}

.about_03 .img {
  justify-content: center;
}

.about_03 .midashi5 article {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.about_03 .midashi5 article div {
  margin-left: 20px;
  max-width: 100px;
  background: red;
  padding: 2px 5px;
  color: #fff;
}

.about_03 .midashi5 article div:empty {
  display: none;
}

.about_03 .flxL {
  width: 40%;
}

@media only screen and (max-width: 640px) {
  .about_03 .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
}

.about_03 .flxL .img {
  flex-wrap: inherit !important;
}

.about_03 .flxL .img img {
  width: 100% !important;
}

.about_03 .flxR {
  width: 55%;
}

@media only screen and (max-width: 640px) {
  .about_03 .flxR {
    width: 100%;
  }
  .about_03 .flxR .btn {
    width: 100%;
  }
}

a.itext {
  width: 100% !important;
  height: auto !important;
}

@media only screen and (max-width: 1200px) {
  .about_04 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .about_04 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .about_04 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.about_04:nth-child(odd) {
  background: none;
}

.about_04 .img {
  justify-content: center;
}

.waku {
  padding: 40px;
  background: #f7f7f7;
  border: solid 1px #ccc;
}

@media only screen and (max-width: 640px) {
  .waku {
    padding: 20px;
  }
}

.guide01 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 640px) {
  .guide01 {
    display: block;
  }
}

.guide01 .flxL {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .guide01 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.guide01 .flxR {
  width: 65%;
  margin-left: 5%;
}

@media only screen and (max-width: 640px) {
  .guide01 .flxR {
    width: 100%;
    margin-left: 0;
  }
}

.faq01 dl {
  margin-bottom: 30px;
  display: block !important;
}

.faq01 dl:last-child {
  margin-bottom: 0 !important;
}

.faq01 dl dt {
  cursor: pointer;
  width: 100% !important;
  font-size: 18px;
  font-weight: 700;
  color: #181818;
  border: 0 !important;
  background: #f7f7f7 !important;
  padding: 10px !important;
  margin-bottom: 10px;
}

@media only screen and (max-width: 640px) {
  .faq01 dl dt {
    font-size: 16px;
  }
}

.faq01 dl dt span {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
}

.faq01 dl dt span:before {
  margin-right: 10px;
  font-family: "Font Awesome 5 Pro";
  content: "\f059";
  font-size: 30px;
  font-weight: 700;
  color: #ff9900;
  vertical-align: middle;
  text-align: right;
  display: block;
}

.faq01 dl dd {
  background-color: #f7f7f7 !important;
  padding: 15px;
  width: 100% !important;
  border: 0 !important;
  margin-bottom: 30px;
}

@media only screen and (max-width: 640px) {
  .faq01 dl dd {
    font-size: 16px;
  }
}

.faq01 dl dd .txt {
  font-size: 16px;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
}

.faq01 dl dd span {
  margin-right: 0 !important;
  line-height: 1.7em !important;
}

.shopwrap {
  margin: 50px 0;
}

.shopwrap .items {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -3.22581% !important;
}

@media only screen and (max-width: 834px) {
  .shopwrap .items {
    justify-content: space-between;
    margin-left: 0 !important;
  }
}

.shopwrap .items li {
  width: 21.77419% !important;
  margin-left: 3.22581% !important;
  margin-bottom: 50px;
}

@media only screen and (max-width: 834px) {
  .shopwrap .items li {
    width: 48% !important;
    margin-bottom: 20px;
    margin-left: 0 !important;
  }
}

.shopwrap .items li .itembox {
  display: block;
  color: #333;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2em;
}

.shopwrap .items li .itembox .summary {
  display: none;
}

.shopwrap .items li .itembox .price {
  text-align: left;
  font-weight: 700;
  color: #000;
  margin-top: 5px;
}

.shopwrap .items li .itembox .imgwrap {
  position: relative;
  width: 100% !important;
  margin-bottom: 15px;
  border: 1px solid #ccc;
}

.shopwrap .items li .itembox .imgwrap img {
  width: 100% !important;
  height: 100% !important;
}

div.search {
  margin: 50px 0;
}

#col_main > section > .search:nth-child(2) {
  position: relative;
}

@media only screen and (max-width: 834px) {
  #col_main > section > .search:nth-child(2) {
    padding: 0px 40px;
  }
}

div.search #keyword .select {
  position: relative;
}

div.search #keyword .select:after {
  top: 15px !important;
  pointer-events: none;
}

div.order > ul:before {
  content: "並び替え:";
  margin: 5px 0 5px 20px;
}

.item {
  max-width: 960px;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 834px) {
  .item {
    max-width: 100%;
  }
}

.item .photo {
  width: 100% !important;
  max-width: 50% !important;
}

@media only screen and (max-width: 834px) {
  .item .photo {
    max-width: 100% !important;
  }
}

.item .item_view {
  margin-left: 0 !important;
  min-width: auto　 !important;
  max-width: 45% !important;
}

@media only screen and (max-width: 834px) {
  .item .item_view {
    max-width: 100% !important;
  }
}

.photo {
  flex-direction: column !important;
}

.photo .large {
  margin-left: 0 !important;
  margin-bottom: 10px !important;
}

.photo .large img {
  width: 100%;
}

.photo .small {
  min-width: 100% !important;
  max-width: 100% !important;
}

.photo .small ul {
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.36842%;
  margin-bottom: 50px;
}

.photo .small ul li {
  position: relative;
  text-align: center;
  border-bottom: 0;
  width: 17.63158%;
  height: 17.63158%;
  margin-left: 2.36842% !important;
  margin-bottom: 10px !important;
  flex-basis: auto !important;
}

.photo .small ul li:before {
  position: relative;
  z-index: 1;
  content: "";
  padding-top: 100%;
  display: block;
  width: 100% !important;
}

.photo .small ul li img {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.photo .small .owl-dots {
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.photo .small .owl-dots .owl-dot {
  border: 6px solid #222;
  border-radius: 50%;
  margin: 0 5px;
}

.photo .small .owl-dots .owl-dot.active {
  border-color: #5d5d5d;
}

.item_view h2 {
  color: #222;
  font-weight: 500;
}

.item_view h2 span {
  display: none !important;
}

.item_view .item_option {
  margin-top: 20px;
}

.item_view .item_option dl {
  width: 100% !important;
}

.item_view .item_option .addcart .count {
  width: 100% !important;
}

.item_view .item_option .addcart .addcart {
  transition: 1s;
  height: 100%;
  padding: 15px;
  width: 100% !important;
  background: #5d5d5d;
  color: #fff;
  text-indent: 0;
}

.item_view .item_option .addcart .addcart:hover {
  opacity: 1;
  background: #3e3e3e;
}

.item_view .item_option .addcart a {
  color: #9a3000;
  text-decoration: none;
}

.item_view .item_option .addcart a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f06a";
  margin-right: 5px;
}

.item_view .struct {
  font-size: 13px;
  margin-top: 10px !important;
}

.item_view .struct .sku {
  margin: 10px 0 !important;
}

.item_view .struct .sku .price {
  font-size: 16px !important;
  line-height: 1 !important;
}

.item_view .sku2 {
  margin-top: 20px;
}

.item_view p.sku1 {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  background: #f7f7f7;
  padding: 15px 15px 10px 15px;
}

.item_view p.sku1:before {
  text-align: center;
  background: #ccc;
  padding: 8px 0;
  width: 100%;
  content: "カラーを選択してください";
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
}

.item_view .sku1 ul {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: #f7f7f7;
  padding: 0 15px 15px 15px;
  margin-top: 0 !important;
  margin-left: 0 !important;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item_view .sku1 ul li.selected {
  background: #000 !important;
}

.item_view .sku1 ul li {
  background: #fff;
  width: 48%;
  margin-left: 0 !important;
  margin-bottom: 10px !important;
}

.item_view .attr {
  margin-top: 20px;
}

.item_view .attr h3 {
  display: none;
}

.item_view .attr dl {
  display: flex;
  border: 0;
  border-top: 1px solid #222;
  border-bottom: none;
  font-size: 13px;
}

.item_view .attr dl dt {
  padding: 10px;
  min-width: 25%;
  background: none;
  border-right: 0;
}

.item_view .attr dl dd {
  padding: 10px;
  width: 100%;
}

.item_view .attr dl:last-child {
  border-bottom: 1px solid #222;
}

.item_view .detail {
  margin-top: 0px;
  font-size: 13px;
  color: #222;
}

.crumb {
  display: none;
}

.contact01 {
  background: #f3f3f3;
  margin: 0 auto;
  padding: 50px;
}

.contact01 dl {
  margin-bottom: 40px;
  font-size: 15px;
}

.contact01 dl:last-child {
  margin-bottom: 0;
}

.contact01 dl dt {
  line-height: 1;
  font-size: 16px;
  max-width: 200px;
  min-width: 200px;
  width: 30% !important;
  font-weight: 700;
  border: 0 !important;
  background-color: inherit !important;
  padding: 0 !important;
  justify-content: flex-start !important;
}

@media only screen and (max-width: 640px) {
  .contact01 dl dt {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
}

.contact01 dl dt label {
  margin-top: 5px;
  background: #ff9900;
  border-radius: 5px;
  color: #fff !important;
  font-size: 13px;
  font-weight: 700;
  padding: 6px;
  line-height: 1;
}

@media only screen and (max-width: 640px) {
  .contact01 dl dt label {
    line-height: 1;
    padding: 3px;
    font-size: 11px;
  }
}

.contact01 dl dd {
  text-align: left;
  width: 80% !important;
  border: 0 !important;
  background-color: inherit !important;
  padding: 0 !important;
}

@media only screen and (max-width: 640px) {
  .contact01 dl dd {
    width: 100% !important;
  }
}

.contact01 dl dd span {
  line-height: 1;
  margin-right: 0;
}

.contact01 .file {
  border-top: 0 !important;
  flex-direction: row !important;
  border-top: 0 !important;
}

@media only screen and (max-width: 640px) {
  .contact01 .file {
    flex-direction: column !important;
  }
}

.contact01 .file dt {
  font-size: 16px;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
}

@media only screen and (max-width: 640px) {
  .contact01 .file dt {
    flex-direction: row !important;
  }
}

.contact01 .file dd label {
  position: static !important;
  margin-left: auto !important;
  margin-bottom: 10px;
}

.contact01 .file dd div {
  line-height: 1.4em;
  color: #666;
  font-size: 14px;
  letter-spacing: -0.05em;
}

.policy a {
  color: #333;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media only screen and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title,
.wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2,
.item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a,
.item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover,
.item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}
